import React from 'react';
import {Paper, Icon} from "@material-ui/core"

const CARD_WIDTH = 290;

export default class PlanningServerCard extends React.Component{

    constructor(props) {
        super(props);

        this.state={
            lastPingLatency:null,
            hasData:null,
            errorCode:null,
        }
    }

    _serverPlanningUpdateSubscription:any;

    componentDidMount(){
        this._serverPlanningUpdateSubscription = global.emitter.addListener("serverPlanningUpdate",(data)=>{
            if(data.serverName === this.props.server.serverName){
                this.setState({
                    lastPingLatency:data.latency,
                    hasData:data.hasData,
                    errorCode:data.errorCode ? data.errorCode : null,
                })
            }
        })
    }

    componentWillUnmount(){
        if(this._serverPlanningUpdateSubscription){this._serverPlanningUpdateSubscription.remove()}
    }

    render(){

        const server = this.props.server;
        const error = this.state.hasData === false;

        return(
            <Paper style={{width:CARD_WIDTH, marginRight:8, marginBottom:8, display:"flex", flexDirection:"column", borderRadius:6,
                overflow:"hidden", padding:4, backgroundColor:error ? "#EE3E3B": "#FFFFFF"}}>

                <div style={{height:"100%", width:"100%", padding:4, backgroundColor:"#FFFFFF", borderRadius:4}}>

                    <div style={{width:"100%", height:24, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',}}>
                        <p style={{width:"100%", margin:0, textAlign:"left", fontSize:16, color:'#222222', fontWeight:"bold", textTransform:"uppercase"}}>
                            {server.serverName}
                        </p>
                    </div>

                    {this.state.errorCode &&
                        <p style={{width:"100%", textAlign:"left", margin:0, color:"#EE3E3B", fontWeight:"bold", fontSize:12, paddingTop:8, paddingBottom:8}}>
                            Une erreur est survenue: {" " + this.state.errorCode}
                        </p>
                    }

                    <div style={{width:25, height:1, backgroundColor:"#CCCCCC", marginTop:4, marginBottom:4}}/>

                    <div style={{width:"100%", display:"flex", flexDirection:"row", marginTop:8, alignItems:"flex-start", justifyContent:"center"}}>
                        {!error &&
                        <div style={{ padding:1, paddingLeft:4, paddingRight:5, borderRadius:10, display:"flex", flexDirection:"row",
                            justifyContent:"center", alignItems:'center', backgroundColor:'#2CBE4E', opacity:0.7}}>
                            <Icon style={{color:"#EFEFEF", fontSize:14, marginRight:4}}>check</Icon>
                            <span style={{color:'#EFEFEF', fontSize:10, textAlign:"left", fontWeight:"bold"}}>R.A.S</span>
                        </div>
                        }

                        {error &&
                        <div style={{ padding:1, paddingLeft:4, paddingRight:5, borderRadius:10, display:"flex", flexDirection:"row",
                            justifyContent:"center", alignItems:'center', backgroundColor:'#EE3E3B'}}>
                            <Icon style={{color:"#EFEFEF", fontSize:12, marginRight:4}}>warning</Icon>
                            <span style={{color:'#EFEFEF', fontSize:10, textAlign:"left", fontWeight:"bold"}}>NO_EVENTS</span>
                        </div>
                        }

                        <p style={{color:this.state.lastPingLatency ? "#222222" : "#797979", fontSize:14, textAlign:"left", margin:0, flex:1, paddingLeft:8}}>
                            {this.state.lastPingLatency ? this.state.lastPingLatency + " ms" : "---"}
                        </p>

                    </div>


                </div>


            </Paper>
        )
    }

}
