import moment from "moment";
export class EventCheckInstance{

    serverName:string;
    serverUrl:string;
    groupId:number;

    username:string = "hubup";
    password:string = "LovePikachu!2016";

    jwt;

    error:string;


    constructor(serverName,serverUrl,groupId){
        this.serverName = serverName;
        this.serverUrl = serverUrl;
        this.groupId = groupId;

        this.login()
            .then(()=>{
                global.emitter.emit("serverInstanciated",this.serverName)
            })
            .catch((err)=>{
                console.log(err);
            })
    }

    login():Promise<any>{
        const body = {_password:this.password,_username:this.username};
        return new Promise((resolve,reject)=>{
            fetch(this.serverUrl + "/api/login_check",{
                method:"POST",
                body:JSON.stringify(body),
                headers:new Headers({'Content-Type':'application/json'})
            })
                .then((res)=>{return res.json()})
                .then((data)=>{this.jwt = data.token; resolve()})
                .catch((err)=>{this.error = "LOGIN_FAIL"; reject(err)})
        })
    }

    tryGetEvents(){

        const startTime = moment().startOf("day").unix();
        const endTime = moment().endOf("day").unix();
        const url = this.serverUrl + "/api/scheduler/events/" + this.groupId + "/lights/" + startTime + "/groups/" + endTime + "/date";

        let t0 = performance.now()

        fetch(url,{
            method:"GET",
            headers:new Headers({
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + this.jwt
            })
        })
            .then((res)=>{
                return res.json()
            })
            .then((data)=>{
                let t1 = performance.now()

                if(data.status === 401){
                    this.handle401();
                }
                else if(data["detail-dev-message"]){
                    global.emitter.emit("serverPlanningUpdate",{
                        serverName:this.serverName,
                        hasData:null,
                        errorCode:"Erreur serveur",
                        latency:Math.floor(t1 - t0),
                    })
                }
                else{
                    global.emitter.emit("serverPlanningUpdate",{
                        serverName:this.serverName,
                        hasData:data && data.length > 0,
                        errorCode:null,
                        latency:Math.floor(t1 - t0),
                    })
                }


            })
            .catch((err)=>{
                console.log(err)
            })
    }

    handle401(){
        if(this.error === "LOGIN_FAIL"){return};
        this.login()
            .then(()=>{})
            .catch((err)=>{})
    }

}
