import React from 'react';
import './App.css';
import {Paper, Icon, CircularProgress} from "@material-ui/core"
import moment from "moment";
import ServerCard from "./components/ServerCard";
import {EventEmitter} from 'fbemitter';
import Clock from "./components/Clock";
import PlanningExistenceMonitoring from "./components/PlanningsExistenceMonitoring/PlanningExistenceMonitoring";

export default class App extends React.Component{

    constructor(props) {
        super(props);

        this.state={
            authorized:false,

            servers:[],
            errorServers:[],
            lastPingDateTime:null,
        }

        global.emitter = new EventEmitter();
    }

    _fetchInterval:any;

    componentDidMount(){
        if(this.state.authorized === false){
            let password = window.prompt("Please enter password to continue");
            if(password === "LovePikachu!2016"){
                this.setState({
                    authorized:true,
                },()=>{
                    this.componentDidAuthorize();
                })
            }
        }
    }

    componentDidAuthorize(){
        // this.fetchStatisticsData();
        // this._fetchInterval = setInterval(()=>{
        //     this.fetchStatisticsData();
        // },60*1000)
    }

    componentWillUnmount(){
        if(this._fetchInterval){clearInterval(this._fetchInterval)}
    }

    fetchStatisticsData(){
        this.fetchServerPings()
            .then((servers)=>{
                let lastPingDateTime = null;
                let errorServers = [];

                if(servers.length > 0){
                    let ping = servers[0].pings[ servers[0].pings.length - 1];
                    if(ping){
                        lastPingDateTime = moment(ping.dateTime)
                    }
                }

                for(let server of servers){
                    if(server.lastPing.isFailing === true){
                        errorServers.push(server);
                    }
                }

                this.setState({
                    servers:servers,
                    errorServers:errorServers,
                    lastPingDateTime:lastPingDateTime,
                },()=>{
                    global.emitter.emit("serverPingsUpdated");
                })
            })
    }

    render(){

        if(this.state.authorized === false){return null}

        if(1 === 1){
            return(
                <PlanningExistenceMonitoring/>
            )
        }

        return(
            <div style={{width:"100%", height:"100%", backgroundColor:"#313540", position:"relative",
            display:"flex", flexDirection:"row"}}>

                <div style={{width:300, height:"100%", position:"relative"}}>

                    <div style={{height:60, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                    paddingLeft:10, paddingRight:10}}>

                        <div style={{height:30, width:30,display:"flex", justifyContent:"center", alignItems:'center', marginRight:2}}>
                            <img alt={"company_logo"} src={require("./assets/logo_hubup_squared_v2_white.svg")} style={{maxHeight:"100%", width:"100%"}}/>
                        </div>
                        <p style={{color:"#FFFFFF", paddingLeft:10, fontSize:18, paddingTop:1, flex:1}}>Status</p>

                        <Clock/>
                    </div>
                    <div style={{width:'calc(100% - 20px)', marginLeft:10, height:1,backgroundColor:"#797979"}}/>

                    <div style={{width:"100%", height:50, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                    paddingLeft:10, paddingRight:10}}>
                        <p style={{flex:1, textAlign:"left", fontSize:14, color:"#FFFFFF", margin:0}}>
                            Dernière mise à jour:
                            <strong>{this.state.lastPingDateTime ? " " + this.state.lastPingDateTime.format("HH:mm:ss") : ""}</strong>
                        </p>
                        <CircularProgress size={20} style={{color:"#FFFFFF"}}/>
                    </div>

                    <div style={{width:'calc(100% - 20px)', marginLeft:10, height:1,backgroundColor:"#797979"}}/>

                    <div style={{width:"100%", height:"calc(100vh - 112px)"}}>


                        {this.state.lastPingDateTime !== null && this.state.errorServers.length > 0 &&
                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center",
                                flexDirection:"column"}}>
                                <p style={{color:'#EFEFEF', fontSize:14, textAlign:"center", padding:10, paddingBottom:0}}>
                                   Certains serveurs sont injoignables ou présentent des erreurs, ceux-ci sont affichés dans la liste ci-dessous
                                </p>

                                {this.state.errorServers.map((server)=>{
                                    return(
                                        <div key={"err_serv_" + server.tag} style={{ width:"100%", padding:5, paddingLeft:10, paddingRight:10,}}>
                                            <div style={{height:30,width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center",
                                            padding:4, backgroundColor:"#EE3E3B", borderRadius:4, paddingLeft:10}}>
                                                <Icon style={{color:"#FFFFFF", fontSize:18, marginRight:8}}>warning</Icon>
                                                <span style={{color:"#FFFFFF", fontWeight:"bold", textAlign:"left", textTransform:"uppercase"}}>{server.tag}</span>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        }

                        {this.state.lastPingDateTime !== null && this.state.errorServers.length === 0 &&
                            <div style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:'center', padding:20,
                                flexDirection:"column"}}>
                                <p style={{color:'#EFEFEF', fontSize:14, textAlign:"center", padding:10, paddingBottom:0}}>
                                    Tous les serveurs sont opérationnels, aucun dysfonctionnement d'accès aux services à signaler
                                </p>

                                <div style={{ padding:1, paddingLeft:8, paddingRight:10, borderRadius:10, display:"flex", flexDirection:"row",
                                    justifyContent:"center", alignItems:'center', backgroundColor:'#2CBE4E'}}>
                                    <Icon style={{color:"#EFEFEF", fontSize:20, marginRight:4}}>check</Icon>
                                    <span style={{color:'#EFEFEF', fontSize:14, textAlign:"left", fontWeight:"bold"}}>R.A.S</span>
                                </div>

                            </div>
                        }



                    </div>

                </div>

                <div style={{width:"calc(100% - 300px)", height:"100%", padding:4}}>

                    <Paper style={{width:"100%", height:"100%", borderRadius:4, padding:4}}>
                        <div style={{width:"100%", height:"100%", borderRadius:4, backgroundColor:"#EFEFEF", border:"1px solid #CCCCCC"}}>
                            {this.renderServersOverview()}
                        </div>
                    </Paper>

                </div>

            </div>
        )
    }

    renderServersOverview(){
        return(
            <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"row", flexWrap:"wrap", paddingLeft:8, paddingTop:8,
            overflowY:"auto", alignContent:"flex-start"}}>

                {this.state.servers.map((server)=>{

                    return(
                        <ServerCard
                            key={server.tag}
                            server={server}
                        />
                    )

                })}

            </div>
        )
    }

    fetchServerPings(){

        return new Promise((resolve,reject)=>{
            let startDateTime = moment().startOf("day").toISOString()
            let endDateTime = moment().endOf("day").toISOString()

            let url = "https://ndev.api.hubup.cloud/gp/ivy/dynamo/" + startDateTime + "/logs/" + endDateTime + "/between";

            fetch(url)
                .then((response)=>{
                    return response.json()
                })
                .then((rawData)=>{

                    let items = rawData;

                    let servers = [];
                    if(items.length > 0){
                        let tmpVal = items[0].latencies
                        for(let item of tmpVal){
                            servers.push({
                                tag:item.server,
                                pings:[]
                            })
                        }
                    }

                    let orderedServerPings = items.sort((a,b)=>{
                        return a.executionDateTime.S < b.executionDateTime.S ? - 1 : 1;
                    })

                    for(let osp of orderedServerPings){
                        let servs = osp.latencies

                        for(let s of servs){
                            let match = servers.find((item)=>{return item.tag === s.server})
                            if(match){
                                match.pings.push({
                                    dateTime:osp.executionDateTime,
                                    latency:s.fullLatency,
                                    isFailing:s.isFailing
                                })
                            }
                        }
                    }

                    for(let server of servers){
                        server.pings = server.pings.sort((a,b)=>{return a.dateTime < b.dateTime ? -1 : 1});
                        server.lastPing = server.pings[server.pings.length - 1];
                    }

                    servers = servers.sort((a,b)=>{return a.tag < b.tag ? -1 : 1})

                    resolve(servers)

                })
                .catch((err)=>{
                    reject(err);
                })
        })
    }
}

