import React from 'react';
import {Paper, Icon} from "@material-ui/core"
import Chart from "chart.js"
import moment from "moment";

const CARD_WIDTH = 290;

export default class ServerCard extends React.Component{

    _serverPingsUpdatedSubscription:any;

    componentDidMount(){
        this.drawChart();
        this._serverPingsUpdatedSubscription = global.emitter.addListener("serverPingsUpdated",()=>{
            this.drawChart();
        })
    }

    componentWillUnmount(){
        if(this._serverPingsUpdatedSubscription){this._serverPingsUpdatedSubscription.remove()}
    }

    _chartRef:any;
    _prevDataLength:any = null;

    drawChart(){

        const server = this.props.server;

        let ctx = document.getElementById("chart_" + server.tag).getContext('2d');

        let allPings = server.pings;

        let data = {
            labels:allPings.map((i)=>{return moment(i.dateTime).format("HH:mm")}),
            datasets:[
                {
                    data:allPings.map((i)=>{return i.latency}),
                    borderColor:"#1079FC",
                    fill:false,
                    type:"line",
                    yAxisID:"A",
                    order:1,
                },
                {
                    data:allPings.map((i)=>{return i.isFailing === true ? 1 : 0}),
                    borderWidth:0,
                    backgroundColor:"rgb(238,62,59,0.2)",
                    fill:true,
                    type:"bar",
                    yAxisID:"B",
                    order:2,
                    barPercentage: 1,
                    categoryPercentage: 1.0,
                }
            ]
        }

        let options =  {
            responsive: true,
            maintainAspectRatio:false,
            title: {display: false,},
            legend: {display: false},
            elements: { point: { radius: 0 } },
            tooltips: {
                filter: function (tooltipItem) {
                    return tooltipItem.datasetIndex === 0;
                }
            },
            scales:{
                yAxes: [
                    {
                        id:"A",
                        position:"left",
                        display: true,
                        ticks: {
                            beginAtZero: true,
                        }
                    },
                    {
                        id:"B",
                        position:"right",
                        display: false,
                        ticks: {
                            beginAtZero: true,
                        }
                    },
                ],
                xAxes: [{
                    display: true,
                    offset: true,
                }]
            }
        };

        if(!this._chartRef){
            this._chartRef = new Chart(ctx, {
                type: 'line',
                data: data,
                options: options
            });
            this._prevDataLength = data.datasets[0].data.length;
        }
        else{

            if(this._prevDataLength && data.datasets[0].data.length === this._prevDataLength){
                //Data hasn't changed, aborting
                return;
            }

            this._prevDataLength = data.datasets[0].data.length;

            this._chartRef.data = data;
            this._chartRef.update();
        }
    }

    render(){

        const server = this.props.server;
        const error = server.lastPing.isFailing === true;

        return(
            <Paper style={{width:CARD_WIDTH, marginRight:8, marginBottom:8, display:"flex", flexDirection:"column", borderRadius:6,
                overflow:"hidden", padding:4, backgroundColor:error ? "#EE3E3B": "#FFFFFF"}}>

                <div style={{height:"100%", width:"100%", padding:4, backgroundColor:"#FFFFFF", borderRadius:4}}>

                    <div style={{width:"100%", height:24, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',}}>
                        <p style={{width:"100%", margin:0, textAlign:"left", fontSize:16, color:'#222222', fontWeight:"bold", textTransform:"uppercase"}}>
                            {server.tag}
                        </p>
                    </div>

                    <div style={{width:25, height:1, backgroundColor:"#CCCCCC", marginTop:4, marginBottom:4}}/>

                    <div style={{width:"100%", display:"flex", flexDirection:"row", marginTop:8, alignItems:"flex-start", justifyContent:"center"}}>
                        {!error &&
                        <div style={{ padding:1, paddingLeft:4, paddingRight:5, borderRadius:10, display:"flex", flexDirection:"row",
                            justifyContent:"center", alignItems:'center', backgroundColor:'#2CBE4E', opacity:0.7}}>
                            <Icon style={{color:"#EFEFEF", fontSize:14, marginRight:4}}>check</Icon>
                            <span style={{color:'#EFEFEF', fontSize:10, textAlign:"left", fontWeight:"bold"}}>R.A.S</span>
                        </div>
                        }

                        {error &&
                        <div style={{ padding:1, paddingLeft:4, paddingRight:5, borderRadius:10, display:"flex", flexDirection:"row",
                            justifyContent:"center", alignItems:'center', backgroundColor:'#EE3E3B'}}>
                            <Icon style={{color:"#EFEFEF", fontSize:12, marginRight:4}}>warning</Icon>
                            <span style={{color:'#EFEFEF', fontSize:10, textAlign:"left", fontWeight:"bold"}}>Erreur</span>
                        </div>
                        }

                        <p style={{color:server.lastPing.latency ? "#222222" : "#797979", fontSize:14, textAlign:"left", margin:0, flex:1, paddingLeft:8}}>
                            {server.lastPing.latency ? server.lastPing.latency + " ms" : "---"}
                        </p>

                    </div>

                    <div style={{width:CARD_WIDTH - 16, height:90, marginTop:10}}>
                        <canvas
                            id={"chart_" + server.tag}
                            width={CARD_WIDTH - 16}
                            height={90}
                        />
                    </div>

                </div>


            </Paper>
        )
    }

}
