import React, {PureComponent} from 'react';
import moment from "moment";

export default class Clock extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            dateTime:moment().format("HH:mm:ss")
        }
    }

    _clockInterval:any;

    componentDidMount(){

        this._clockInterval = setInterval(()=>{
            this.setState({
                dateTime:moment().format("HH:mm:ss")
            })
        },1000)

    }

    componentWillUnmount(){
        if(this._clockInterval){
            clearInterval(this._clockInterval);
            this._clockInterval = null;
        }
    }

    render(){
        return(
            <div style={{height:32, borderRadius:4, border:"1px solid #EFEFEF", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
            paddingLeft:8, paddingRight:8, backgroundColor:"rgba(255,255,255,0.1)"}}>
                <span style={{color:'#FFFFFF', fontSize:14, fontWeight:"bold"}}>{this.state.dateTime}</span>
            </div>
        )
    }
}
