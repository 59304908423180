import React from 'react';
import {Paper, Icon, CircularProgress, Button} from "@material-ui/core"
import moment from "moment";
import {EventEmitter} from 'fbemitter';
import Clock from "./../Clock";
import {EventCheckInstance} from "./EventCheckInstance";
import ServerCard from "../ServerCard";
import PlanningServerCard from "./PlanningServerCard";

export default class PlanningExistenceMonitoring extends React.Component{

    constructor(props) {
        super(props);

        this.state={
            lastUpdate:null,
            servers:[],
        }
    }

    _eventCheckInstances:Array<EventCheckInstance> = [];
    _serverInstanciatedSubscription:any;
    _interval:any;

    componentDidMount(){
        this.initEventCheckInstances();
        this._interval = setInterval(()=>{
            this.checkForEvents();
        },10*60*1000)
    }

    componentWillUnmount(){
        if(this._serverInstanciatedSubscription){this._serverInstanciatedSubscription.remove()}
        clearInterval(this._interval)
    }

    initEventCheckInstances(){
        this._serverInstanciatedSubscription = global.emitter.addListener("serverInstanciated",(serverName)=>{this.handleNewServer(serverName);})

        //We add all servers
        this._eventCheckInstances.push(new EventCheckInstance("CEOBUS","https://ceobus.api.hubup.fr",23));
        this._eventCheckInstances.push(new EventCheckInstance("RTM","https://rtm.api.hubup.fr",29));
        this._eventCheckInstances.push(new EventCheckInstance("MOBICITE","https://mobicite.api.hubup.fr",27));
        this._eventCheckInstances.push(new EventCheckInstance("PERRIER","https://perier.api.hubup.fr",28));

        setTimeout(()=>{
            this.checkForEvents();
        },5*1000)
    }

    handleNewServer(serverName){
        let servers = this.state.servers.slice();
        servers.push({
            serverName:serverName,
            error:null,
            data:null,
        })
        this.setState({
            servers:servers.sort((a,b)=>{return a.serverName < b.serverName ? -1 : 1}),
        })
    }

    checkForEvents(){
        this.setState({lastUpdate:moment()})
        for(let eventCheckInstance of this._eventCheckInstances){
            eventCheckInstance.tryGetEvents()
        }
    }

    render(){
        return(
            <div style={{width:"100%", height:"100%", backgroundColor:"#313540", position:"relative",
                display:"flex", flexDirection:"row"}}>

                <div style={{width:300, height:"100%", position:"relative"}}>

                    <div style={{height:60, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                        paddingLeft:10, paddingRight:10}}>

                        <div style={{height:30, width:30,display:"flex", justifyContent:"center", alignItems:'center', marginRight:2}}>
                            <img alt={"company_logo"} src={require("./../../assets/logo_hubup_squared_v2_white.svg")} style={{maxHeight:"100%", width:"100%"}}/>
                        </div>
                        <p style={{color:"#FFFFFF", paddingLeft:10, fontSize:18, paddingTop:1, flex:1}}>Plannings</p>

                        <Clock/>
                    </div>
                    <div style={{width:'calc(100% - 20px)', marginLeft:10, height:1,backgroundColor:"#797979"}}/>

                    <div style={{width:"100%", height:50, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:'center',
                        paddingLeft:10, paddingRight:10}}>
                        <p style={{flex:1, textAlign:"left", fontSize:14, color:"#FFFFFF", margin:0}}>
                            Dernière mise à jour:
                            <strong>{this.state.lastUpdate ? " " + this.state.lastUpdate.format("HH:mm:ss") : ""}</strong>
                        </p>
                        <CircularProgress size={20} style={{color:"#FFFFFF"}}/>
                    </div>

                    <div style={{width:'calc(100% - 20px)', marginLeft:10, height:1,backgroundColor:"#797979"}}/>

                    <div style={{width:"100%", height:"calc(100vh - 112px)"}}>

                        {/*<Button onClick={()=>{this.checkForEvents()}}>*/}
                        {/*    check for events*/}
                        {/*</Button>*/}

                    </div>

                </div>
                <div style={{width:"calc(100% - 300px)", height:"100%", padding:4, backgroundColor:"#FAFAFA"}}>

                    <div style={{width:"100%", height:"100%", display:"flex", flexDirection:"row", flexWrap:"wrap", paddingLeft:8, paddingTop:8,
                        overflowY:"auto", alignContent:"flex-start"}}>

                        {this.state.servers.map((server)=>{
                            return(
                                <PlanningServerCard
                                    key={server.serverName}
                                    server={server}
                                />
                            )
                        })}

                    </div>

                </div>
            </div>
        )
    }
}
